import React, { useEffect } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";
import { Container, CircularProgress, IconButton, Chip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { bookDetail } from "src/DAL/Books/Books";
import { s3baseUrl } from "src/config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function BookDetail() {
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await bookDetail(state?.detailValues._id);
    if (result.code === 200) {
      setProgrammeDetailInfo(result.data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    handleDetail();
  }, []);
  useEffect(() => {
    setIsLoaded(false);
  }, [programmeDetailInfo]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  const getDocumentUrl = (fileUrl) => {
    console.log(fileUrl, "fileUrlfileUrlfileUrlfileUrl");
    const extension = fileUrl.split(".").pop();
    if (["pdf"].includes(extension)) {
      return s3baseUrl + fileUrl;
    } else if (["doc", "docx", "xls", "xlsx", "csv"].includes(extension)) {
      return `https://docs.google.com/gview?url=${
        s3baseUrl + fileUrl
      }&embedded=true`;
    }
    return s3baseUrl + fileUrl;
  };

  let breadCrumbMenu = [
    { title: "Books", navigation: `/books`, active: false },
    { title: programmeDetailInfo?.title, navigation: null, active: true },
  ];
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/books")}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="book-detail-title">{programmeDetailInfo?.title}</h2>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-2">
            <img
              width={120}
              height="auto"
              src={s3baseUrl + programmeDetailInfo?.image}
              alt="Group Image"
              className="img-fluid"
            />
          </div>
          <div className="col-10">
            <div>
              <h5 className="card-title">{programmeDetailInfo?.title}</h5>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: programmeDetailInfo?.detailed_description,
                  }}
                ></div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="tags-section">
        {programmeDetailInfo?.tags?.length > 0 && (
          <div>
            <div
              className="tags d-flex flex-wrap"
              style={{
                display: "flex",
                flexWrap: "wrap", // Allows wrapping tags to the next line
              }}
            >
              <p
                className="mt-2"
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "white",
                  marginRight: "8px",
                }}
              >
                Book Tags:
              </p>
              {programmeDetailInfo.tags.map((tag) => (
                <span key={tag._id}>
                  <Chip
                    label={tag.name}
                    className="tag"
                    style={{
                      margin: "4px",
                      color: "black",
                      backgroundColor: "white",
                      fontWeight: "bold",
                    }}
                  />
                </span>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="books-detail-document">
        {programmeDetailInfo?.type == "document_file" && (
          <>
            {!isLoaded && (
              <div className="loading-preview">
                <CircularProgress className="loading-preview-svg" />
              </div>
            )}
            {programmeDetailInfo &&
              programmeDetailInfo.type == "document_file" && (
                <iframe
                  className="other-resource"
                  width="100%"
                  frameborder="0"
                  preload="auto"
                  onLoad={() => setIsLoaded(true)}
                  onError={() => console.log("Error loading document")}
                  src={getDocumentUrl(programmeDetailInfo.document_file)}
                ></iframe>
              )}
          </>
        )}
      </div>
      <div className="books-detail-document">
        {programmeDetailInfo?.type == "external_url" && (
          <>
            <iframe
              className="other-resource"
              width="100%"
              frameborder="0"
              preload="auto"
              // onLoad={() => setIsLoaded(true)}
              onError={() => console.log("Error loading document")}
              // onError={console.log("Error loading document")}
              // src={`https://docs.google.com/gview?url=${programmeDetailInfo.document_file}&embedded=true`}
              src={programmeDetailInfo.external_url}
            ></iframe>
          </>
        )}
      </div>
    </div>
  );
}
